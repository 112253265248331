import axios, { CancelToken } from 'axios';
import { PagedResult } from 'types/common';
import { BirthdayMessage } from 'types/sp-api';

class BirthdayMessagesService {
    public async getUnreadCountAsync(organizationId?: number, cancelToken?: CancelToken): Promise<number> {
        const params = new URLSearchParams();
        params.append('query', 'unread==true');

        const response = await axios.get<number>(`birthdaymessages/count/${organizationId ?? 0}`, {
            params: params,
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async getOrganizationMessageAsync(
        organizationId: number,
        cancelToken?: CancelToken
    ): Promise<BirthdayMessage[]> {
        const response = await axios.get<PagedResult<BirthdayMessage[]>>(
            `birthdaymessages/organization/${organizationId}`,
            {
                cancelToken: cancelToken
            }
        );
        return response.data.content;
    }

    public async getMessageAsync(messageId: number, cancelToken?: CancelToken): Promise<BirthdayMessage> {
        const response = await axios.get<BirthdayMessage>(`birthdaymessages/${messageId}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async saveMessageAsync(message: BirthdayMessage): Promise<void> {
        if (message?.id && message.id > 0) {
            await axios.put<BirthdayMessage>(`birthdaymessages/${message?.id}`, message);
        } else {
            await axios.post<BirthdayMessage>(`birthdaymessages/`, message);
        }
    }

    public async deleteMessageAsync(messageId: number): Promise<void> {
        await axios.delete<void>(`birthdaymessages/${messageId}`);
    }

    public async testMessageAsync(message: BirthdayMessage): Promise<void> {
        await axios.post<BirthdayMessage>(`birthdaymessages/testMessage`, message);
    }
}

export default new BirthdayMessagesService();
