import React, { FC, useContext } from 'react';
import { LanguageContext, LanguageTextFunc } from 'contexts/languageContext';
import { BirthdayMessage } from 'types/sp-api';
import { useErrorHandler } from 'components/hooks/useErrorHandler';
import BirthdayMessagesService from 'services/birthdayMessagesService';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import LoadButton from 'components/common/LoadButton';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useSuccessHandler } from 'components/hooks/useSuccessHandler';
import FormFieldTextArea from 'components/common/FormFieldTextArea';
import { useNavigate } from 'react-router-dom';
import OrganizationSmsSender from 'components/smsmessages/OrganizationSmsSender';
import FormFieldSwitch from 'components/common/FormFieldSwitch';
import LightTooltip from 'components/common/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';

const getValidationSchema = (T: LanguageTextFunc) => {
    return Yup.object().shape({
        message: Yup.string().required(T('Common_Required'))
    });
};

interface BirthdayMessageItemProps {
    message?: BirthdayMessage;
    organizationId: number;
}

const BirthdayMessageItem: FC<BirthdayMessageItemProps> = ({ organizationId, message }: BirthdayMessageItemProps) => {
    const { T } = useContext(LanguageContext);
    const { handleError } = useErrorHandler();
    const { handleSuccess } = useSuccessHandler();
    const navigate = useNavigate();

    const saveMessage = async (newValues: BirthdayMessage): Promise<void> => {
        try {
            await BirthdayMessagesService.saveMessageAsync(newValues);
            handleSuccess(T('BirthdayMessage_MessageSaveSuccess'));
            navigate('/communication/birthdaymessages');
        } catch (error) {
            handleError(error, T('BirthdayMessage_MessageSaveFailed'));
        }
    };

    const testMessage = async (newValues: BirthdayMessage): Promise<void> => {
        try {
            await BirthdayMessagesService.testMessageAsync(newValues);
            handleSuccess(T('BirthdayMessage_MessageTestSuccess'));
            navigate('/communication/birthdaymessages');
        } catch (error) {
            handleError(error, T('BirthdayMessage_MessageTestFailed'));
        }
    };

    return (
        <Formik
            initialValues={
                message ?? {
                    title: '',
                    message: '',
                    type: 0,
                    active: false,
                    sentCount: 0,
                    organizationId: organizationId
                }
            }
            enableReinitialize
            validationSchema={getValidationSchema(T)}
            onSubmit={saveMessage}
        >
            {({ values, dirty, isValid, isSubmitting, resetForm }: FormikProps<BirthdayMessage>) => (
                <Form>
                    <Grid item xs={12}>
                        <Card className="message-item">
                            <CardContent
                                sx={{
                                    backgroundColor: 'white',
                                    borderWidth: 0,
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'primary.main',
                                    borderBottomStyle: 'solid'
                                }}
                            >
                                <Grid container spacing={1}>
                                    <Grid container item>
                                        <FormFieldSwitch
                                            name="active"
                                            label={T(
                                                values.active ? 'BirthdayMessage_ActiveOn' : 'BirthdayMessage_ActiveOff'
                                            )}
                                        />
                                    </Grid>
                                    <Grid container item>
                                        <Typography variant="subtitle1" color="textSecondary" noWrap mr={1}>
                                            {`${T('BirthdayMessage_Sender')}: `}
                                            {<OrganizationSmsSender organizationId={organizationId} plainText />}
                                        </Typography>
                                        <LightTooltip title={T('BirthdayMessage_SmsSenderTooltip')}>
                                            <InfoIcon color="info" fontSize="small" />
                                        </LightTooltip>
                                    </Grid>
                                </Grid>
                                <FormFieldTextArea name="message" label={T('BirthdayMessage_Message')} rows={12} />
                            </CardContent>
                            <CardActions>
                                <Box m={1}>
                                    <LoadButton
                                        style={{ marginRight: 8 }}
                                        loading={isSubmitting}
                                        disabled={!dirty || !isValid}
                                        type="submit"
                                    >
                                        {T('BirthdayMessage_SaveMessage')}
                                    </LoadButton>
                                    <Button
                                        disabled={!dirty && !!values.id}
                                        style={{ marginLeft: 16 }}
                                        onClick={() => {
                                            resetForm();
                                        }}
                                        color="secondary"
                                    >
                                        {T('Common_Cancel')}
                                    </Button>
                                </Box>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid container padding={2} spacing={1} mt={2}>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="GrayText" fontSize={15} fontWeight={'bold'}>
                                {T('BirthdayMessage_HelpTestTitle')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="GrayText">
                                {T('BirthdayMessage_HelpTestText')}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Button
                            disabled={!dirty && values.message == ''}
                            style={{ marginLeft: 16 }}
                            onClick={() => {
                                testMessage(values);
                            }}
                            color="secondary"
                            variant="contained"
                        >
                            {T('BirthdayMessage_MessageTestBtn')}
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default BirthdayMessageItem;
